<template>
    <div class="now-play-homepage--wrapper">
        <logoBar></logoBar>
        <section class="now-play-homepage">
            <navigationButtons></navigationButtons>
            <loading v-if="!isLoaded"></loading>

            <main class="now-play-homepage__logged-in" id="content" >

                <section class="now-play-homepage__search-wrapper">

                    <aside class="search-wrapper__text">
                        <h1> NowPlay </h1>
                        <span>Our video bank has something for everyone.</span>
                    </aside>

                    <aside class="search-wrapper__search-box">
                        <input 
                            type="search" 
                            placeholder="What are you looking for?" 
                            aria-label="What are you looking for?" 
                            role='search' size="25" 
                            ref="search" 
                            v-model="search.term"
                            v-on:keyup.enter="toggleSearchModal"
                        >
                        <font-awesome-icon icon="fas fa-magnifying-glass"  class="search-icon" aria-hidden="true" role="presentation"/>
                    </aside>

                </section>

                <section class="now-play-homepage__welcome-message">
                    <h2 v-if="isLoggedIn"> Welcome back, {{ currentUser.name }}! </h2>
                    <h2 v-else> Welcome! </h2>
                    <p> Simply use the search or take your pick from our fantastic selection of sessions available for you to view on demand.</p>
                </section>

                <section class="now-play-homepage__latest-videos">
                    <div class="now-play-homepage__latest-videos--inner-row">
                        <aside>
                            <span class="line orange-line"></span>
                            <p> Latest Videos</p>
                        </aside>
                        <aside>
                            <router-link tag="button" to="/now_play/latest" class="orange-border"> View all latest videos</router-link>
                        </aside>
                    </div>

                    <div class="now-play-homepage__latest-video--wrapper">
                    <div class="now-play-homepage__latest-video--wrapper-inner" id="latestVideoSlider">
                        <span>
                                <single-video 
                                    v-for="(latestVideo, index) in latestVideos" 
                                    :key="index"
                                    :video="latestVideo"
                                    :isLatestVideo="true"
                                ></single-video>
                        </span>
                    </div>

                        <div class="now-play__single-video__cta-controls-wrapper" v-if="latestVideos.length > 4 ">
                            <div class="now-play__single-video__cta-controls">
                                <button class='orange-background' @click="scrollLeft('latestVideoSlider')">
                                    <i aria-hidden="true" role="presentation">
                                        <font-awesome-icon icon="fas fa-chevron-left" class="arrow-left" aria-hidden="true" role="presentation"/>
                                    </i>
                                </button>
                                <button class='orange-background' @click="scrollRight('latestVideoSlider')">
                                    <i aria-hidden="true" role="presentation">
                                        <font-awesome-icon icon="fas fa-chevron-right" class="arrow-right" aria-hidden="true" role="presentation"/>
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="now-play-homepage__popular-videos grey-background">
                    <div class="now-play-homepage__popular-videos--inner-row">
                        <aside>
                            <span class="line blue-line"></span>
                            <p> Popular Videos</p>
                        </aside>
                        <aside>
                            <router-link tag="button" to="/now_play/popular" class="blue-border"> View all popular videos</router-link>
                        </aside>
                    </div>

                    <div class="now-play-homepage__popular-video--wrapper">

                        <div class="now-play-homepage__popular-video--wrapper-inner" id="popularVideosSlider">
                            <span>
                                <single-video 
                                    v-for="(popularVideo, index) in popularVideos" 
                                    :key="index"
                                    :video="popularVideo"
                                    :isPopularVideo="true"
                                ></single-video>
                            </span>

                        </div>

                        <div class="now-play__single-video__cta-controls-wrapper" v-if="popularVideos.length > 4">
                            <div class="now-play__single-video__cta-controls">
                                <button class='blue-background' @click="scrollLeft('popularVideosSlider')">
                                    <i aria-hidden="true" role="presentation">
                                        <font-awesome-icon icon="fas fa-chevron-left" class="arrow-left" aria-hidden="true" role="presentation"/>
                                    </i>
                                </button>
                                <button class='blue-background' @click="scrollRight('popularVideosSlider')">
                                    <i aria-hidden="true" role="presentation">
                                        <font-awesome-icon icon="fas fa-chevron-right" class="arrow-right" aria-hidden="true" role="presentation"/>
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="now-play-homepage__categories">
                    <div class="now-play-homepage__categories--inner-row">
                        <aside>
                            <span class="line dark-line"></span>
                            <p> Categories</p>
                        </aside>
                        <aside>
                            <router-link tag="button" to="/now_play/categories" class="dark-border"> View all categories </router-link>
                        </aside>
                    </div>

                    <div class="now-play-homepage__categories--list">
                        <single-category 
                            v-for="(category, index) in categories" 
                            :key="index"
                            :category="category"
                            @expandCategory="addExpandedCategory(category)"
                            :toBeExpanded="true"
                        ></single-category>
                    </div>

                    <div class="now-play-homepage__categories--list-expanded" v-show="shouldShowExpandedCategories">
                        <single-category-expanded
                            v-for="(category, index) in expandedCategories" 
                            :key="index"
                            :category="category"
                            :additionalCategories="additionalCategories"
                            :Fablab="FabLab_Videos"
                            :ChildrensLibrary="ChildrensLibrary_Videos"
                            :TrainingPod="TrainigPodVideos"
                        >

                        </single-category-expanded>
                    </div>
                </section>

            </main>
        </section>
    </div>

</template>

<script>
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';
import axios from 'axios';
import Loading from '../shared/loading.vue';
import SingleCategory from './secondary/SingleCategory.vue';
import SingleCategoryExpanded from './secondary/SingleCategoryExpanded.vue';
import SingleVideo from './secondary/SingleVideo.vue';

export default {
    name: 'NowPlayHomepage',
    components: {
        logoBar,
        navigationButtons,
        Loading,
        SingleCategory,
        SingleCategoryExpanded,
        SingleVideo,
    },

    data() {
        return { 
            isLoaded:       false,
            isLoggedIn:     false,
            search: {
                term: '',
            },
            currentUser:    [],
            documents:      [],
            latestVideos:   [],
            popularVideos:  [],
            
            categories:     [],
            additionalCategories: [],
            /// extra category used for linking to categories page
            // and listing additional categories that might exist
            extraCategory: {
                name: 'More Categories',
                description: 'Including Occupational Therapy',
                isOther: true
            },
            expandedCategories: [],
            shouldShowExpandedCategories: false,

            FabLab_Videos:              [],
            ChildrensLibrary_Videos:    [],
            TrainigPodVideos:           [],
        }
    },

    created() {

        this.loadCurrentWebsiteUser();
        this.loadVideos();
    },
    methods: {

        toggleSearchModal() {
            if(this.search.term !== '') {
                this.$router.push({ path: '/now_play/search/' + this.search.term});
            } else {
                this.search.term = 'all-videos';
                this.$router.push({ path: '/now_play/search/' + this.search.term});
            }
        },
        loadCurrentWebsiteUser() {
            axios.get("/website_users/current_user.json").then((response) => {
                if (response.data && response.data.current_website_user && response.data.current_website_user.logged_in) {
                    this.isLoggedIn = true;
                    this.isLoaded   = true;
                    this.currentUser = response.data.current_website_user;
                } else {
                    this.isLoaded = true;
                }
            })
        },
        loadVideos() {

            axios.get('/documents.json?page=1&per_page=10&search_document%5Bterm%5D=').then((response) => {

                // get 3 default categories
                this.categories = response.data.category_schemes[0].categories.slice(0,3);

                // aditional categories
                this.additionalCategories = response.data.category_schemes[0].categories.slice(3);
                
                // add extra category
                this.categories.push(this.extraCategory);

                // get all documents
                this.documents = response.data.documents;

                // sort all documents to categories
                this.sortDocuments();
                
            });

        },
        sortDocuments() {

            this.FabLab_Videos               = this.documents.filter(document => document.categories.includes('Fablab'));
            this.ChildrensLibrary_Videos     = this.documents.filter(document => document.categories.includes("Children's Library"));
            this.TrainigPodVideos            = this.documents.filter(document => document.categories.includes('Training Pod'));

            this.popularVideos = this.documents.filter(document => document.is_most_popular);
 
            this.popularVideos.sort(function (a, b) {
                const key1 = a.views
                const key2 = b.views;

                if (key1 > key2) {
                    return -1;
                } else if (key1 == key2) {
                    return 0;
                } else {
                    return 1;
                }
            });

            this.latestVideos = this.documents.filter(document => document.is_latest);

            this.latestVideos.sort(function (a, b) {
                const key1 = a.created_at;
                const key2 = b.created_at;

                if (key1 > key2) {
                    return -1;
                } else if (key1 == key2) {
                    return 0;
                } else {
                    return 1;
                }
            });


        },

        futureAlert() {
            alert('To be added at a later point');
        },

        addExpandedCategory(category) {

            if (this.expandedCategories.length > 0) {

                // remove previously selected category if any existing
                this.expandedCategories.pop();

                // add new category to an array
                this.expandedCategories.push(category);

                // trigger the UI
                this.shouldShowExpandedCategories = true;

                // check if we need to scroll
                if(this.isMobile) {

                    setTimeout(() => {
                        // scroll down towards the expanded section
                        let section = document.getElementById('categoryTitle');
                        section.scrollIntoView({behavior: 'smooth'});
                    }, 1000);

                } 
            }

            else {

                // add new category to an array
                this.expandedCategories.push(category);

                // trigger the UI
                this.shouldShowExpandedCategories = true;

                // check if we need to scroll
                if(this.isMobile) {

                    setTimeout(() => {
                        // scroll down towards the expanded section
                        let section = document.getElementById('categoryTitle');
                        section.scrollIntoView({behavior: 'smooth'});
                    }, 1000);

                } 

            }
        },

        scrollLeft(ref) {
            const element = document.getElementById(`${ref}`);

            if (element) {
                element.scrollBy({left: -340, behavior: "smooth"});
            }
        },

        scrollRight(ref) {
            const element = document.getElementById(`${ref}`);

            if (element) {
                element.scrollBy({left: 340, behavior: "smooth"});
            }
        }
    },
    computed: {

        isMobile() {
            return(window.screen.width < 768);
        }
    }
}
</script>

<style lang="scss" scoped >
    // .grey-background {
    //     background: #c2c2c2;
    // }
    .now-play-homepage {
        @media screen and (min-width: 768px) {
            position: relative;
            top: -80px;
        }
    }

    .orange-background {
        background:  #d37d0d;
        color: #333333;
    }

    .blue-background {
        background: #1e93be;
    }
</style>