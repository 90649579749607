<template>

    <footer class="vue-footer">

        <section class="vue-footer-row--purple">
            <div class="vue-footer-row--purple-inner">
                <aside class="vue-footer__column">

                    <ul class="vue-footer__navigation">
                        <li>
                            <router-link to="/enquiries/get-help" class="vue-footer__item">Get Help </router-link>
                        </li>

                        <li>
                            <router-link to="/pages/accessibility-statement" class="vue-footer__item">Accessibility Statement </router-link>
                        </li>

                        <li>
                            <router-link to="/pages/terms-and-disclaimer" class="vue-footer__item">Terms and Disclaimer </router-link>
                        </li>

                        <li>
                            <router-link to="/pages/privacy-policy" class="vue-footer__item">Privacy Policy </router-link>
                        </li>

                        <li>
                            <router-link to="/pages/cookie-policy" class="vue-footer__item">Cookie Policy </router-link>
                        </li>
                    </ul>
                    
                    

                    <div class="vue-footer__column--images">
                    
                        <span>
                            <p> Supported by</p>
                            <a href="/" rel="nofollow noreferer" target="_blank">
                                <img alt="Hartlepool community hub" src="/images/hartlepoolatn/hp-footer-logo.png" class="hp-hubs">
                            </a>
                        </span>

                            <a href="https://www.hartlepool.gov.uk/" rel="nofollow noreferer" target="_blank">
                                <img alt="Hartlepool Burough Council" src="/images/hartlepoolatn/HBC-footer-logo.png" class="hartlepool-council">
                            </a>

                    </div>
                </aside>
            </div>


        </section>

        <section class="vue-footer-row--dark">
            <div class="vue-footer-row--dark-inner">
                <aside class="copyright-text">
                    <p> Copyright © {{ currentYear }} Hartlepool Borough Council</p>
                </aside>

                <aside class="focusgov">
                    <p> Website created by</p>
                    <a href="https://www.focusgov.co.uk/" rel="nofollow noreferer" target="_blank"> focusgov </a>
                </aside>
            </div>
        </section>
    </footer>
    
</template>


<script>
export default {
    name: "vueFooter",

    computed: {
        currentYear() {
            let date = new Date();
            return date.getFullYear();
        }
    }
}
</script>
