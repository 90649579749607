<template>
  <div>


    <div class="nav-brand__home">
      <a href="https://www.hartlepoolnow.co.uk/">
        <p class="nav-brand__link">Hartlepool Now Homepage</p>
      </a>
    </div>

    <div class="video-conferencing__homepage-logo">
      <router-link :to="'/'" >
        <img alt="All together now" src="/images/hartlepoolatn/atn.png">
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'logoBar',
  components: {
    // favouriteButton
  },
  data: function () {
    return {}
  },
  methods: {
  },
  computed: {
  },
  created: function () {
  }
}
</script>
