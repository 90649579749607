<template>
  <section class="custom-container video-conferencing__rooms-index">
    <loading v-show="!loaded" :customText="'Loading sessions...'"></loading>
    <logoBar></logoBar>
    <navigationButtons
      class="video-conferencing__navigation-buttons"
    ></navigationButtons>

    <main id="content">
      <section class="my-4">
        <h1 class="sessions-section-title">
          <font-awesome-icon
            icon="fa-calendar"
            aria-hidden="true"
            role="presentation"
          />
          All Sessions
        </h1>
        <p class="ml-2">You are viewing available (virtual) sessions.</p>
      </section>

      <span class="no-sesssions-text" v-if="noRoomsAreScheduled">
        <font-awesome-icon
          icon="fas fa-calendar-day"
          aria-hidden="true"
          role="presentation"
        />
        <h2>
          There aren't any scheduled sessions available. <br />
          Please check again later
        </h2>
      </span>

      <div
        class="video-conferencing__rooms-index-container"
        :class="participant.website_user_id ? 'logged-in' : ''"
      >
        <h2
          class="sessions-section-title"
          v-if="scheduledRoomsToday.length > 0"
        >
          <font-awesome-icon
            icon="fa-calendar"
            aria-hidden="true"
            role="presentation"
          />
          Today's sessions
        </h2>

        <section class="video-conferencing__session-list">
          <template v-if="scheduledRoomsToday.length > 0">
            <template v-for="(room, index) in scheduledRoomsToday">
              <article class="video-conferencing__session-card">
                <div class="video-conferencing__session-card-inner">
                  <time class="video-conferencing__session-card-date">
                    {{ room.descriptive_start_date }}
                  </time>
                </div>
                <div class="video-conferencing__session-card-inner">
                  <img
                    class="video-conferencing__session-card-clock"
                    alt="Clock icon indicating the start time of the session"
                    src="/images/video_conferencing/icon-clock.png"
                  />
                  <p class="video-conferencing__session-card-time">
                    Starts at {{ room.casual_start_time }}
                  </p>
                </div>
                <div class="video-conferencing__session-duration">
                  <img
                    alt="Session duration icon"
                    src="/images/video_conferencing/icon-session-length.png"
                  />
                  <div
                    :class="
                      room.duration.length > 2
                        ? 'video-conferencing__session-duration--long'
                        : 'video-conferencing__session-duration--short'
                    "
                  >
                    <span class="duration-number">{{
                      room.duration.slice(0, -1)
                    }}</span>
                    <span class="duration-letter">{{
                      room.duration.slice(room.duration.length - 1)
                    }}</span>
                  </div>
                </div>
                <h2 class="video-conferencing__session-card-title">
                  {{ room.name }}
                </h2>
                <p class="video-conferencing__session-card-text">
                  {{ room.short_description.slice(0, 130) }}...
                </p>
                <p
                  class="video-conferencing__session-card-participants"
                  v-if="room.participant_limit"
                >
                  <template
                    v-if="
                      room.participant_count > 0 &&
                      room.participant_limit !== null
                    "
                  >
                    Participating: {{ room.participant_count }} /
                    {{ room.participant_limit }}
                  </template>
                  <template
                    v-else-if="
                      room.participant_count > 0 &&
                      room.participant_limit === null
                    "
                  >
                    Participants: {{ room.participant_count }}
                  </template>
                  <template v-else> Be the first to join the session </template>
                </p>

                <div class="video-conferencing__session-card-buttons">
                  <p
                    class="video-conferencing__session-card-non-bookable"
                    v-if="!room.bookable"
                    aria-live="assertive"
                  >
                    You must be logged in to book this session
                  </p>

                  <router-link
                    v-if="
                      !room.current_user_booked &&
                      (room.participant_limit === null ||
                        room.participant_count < room.participant_limit) &&
                      room.bookable
                    "
                    :to="'/book/' + room.uuid"
                    tag="button"
                    class="video-conferencing__session-book"
                    role="button"
                    @keydown.enter="handleEnterKey"
                    @keydown.space.prevent="handleSpaceKey"
                    tabindex="0"
                  >
                    Book Now
                  </router-link>

                  <button
                    class="video-conferencing__session-cancel"
                    v-if="
                      room.current_user_booked &&
                      !shouldShowCancelBookingWarning &&
                      !room.joinable
                    "
                    @click="prepareToCancelBooking()"
                  >
                    Cancel Booking
                  </button>

                  <button
                    class="video-conferencing__session-cancel"
                    v-if="
                      room.current_user_booked && shouldShowCancelBookingWarning
                    "
                    @click="cancelBooking(room)"
                  >
                    Are you sure?
                  </button>

                  <button
                    class="video-conferencing__session-join"
                    v-if="room.current_user_booked && room.joinable"
                    @click="joinRoom(room)"
                  >
                    Join Session
                  </button>

                  <button
                    @click="toggleIndividualSessionModal(room)"
                    v-if="
                      room.participant_limit === null ||
                      room.participant_count < room.participant_limit ||
                      room.current_user_booked
                    "
                    class="video-conferencing__session-more"
                  >
                    More Info
                  </button>

                  <template
                    v-if="
                      !room.current_user_booked &&
                      room.participant_limit &&
                      room.participant_count === room.participant_limit
                    "
                  >
                    <p class="video-conferencing__session-full">Full!</p>
                  </template>
                </div>
              </article>
              <!-- <hr class="video-conferencing__session-divider"> -->
            </template>
          </template>
        </section>
      </div>

      <section
        class="video-conferencing__session-table"
        v-if="roomsNextWeek.length > 0 || roomsThisWeek.length > 0"
      >
        <h2 class="sessions-section-title">
          <font-awesome-icon
            icon="fa-calendar"
            aria-hidden="true"
            role="presentation"
          />
          Session Calendar
        </h2>
        <sessions-table
          :roomsNextWeek="roomsNextWeek"
          :roomsThisWeek="roomsThisWeek"
        ></sessions-table>
      </section>

      <individual-session-modal
        v-if="shouldShowIndividualSessionModal == true"
        :room="openedSession"
        :participant="participant"
        @closeIndividualSessionModal="toggleIndividualSessionModal()"
      ></individual-session-modal>
    </main>
  </section>
</template>

<script>
import axios from "axios";
import logoBar from "javascripts/video_conferencing/components/shared/logoBar";
import NotificationBanner from "./components/shared/notificationBanner.vue";
import Loading from "./components/shared/loading.vue";
import navigationButtons from "javascripts/video_conferencing/components/shared/navigationButtons";
import SessionsTable from "./components/sessions/SessionsTable.vue";
import IndividualSessionModal from "./components/sessions/IndividualSessionModal.vue";

export default {
  name: "videoConferencingScheduledRoomsList",
  components: {
    logoBar,
    NotificationBanner,
    Loading,
    navigationButtons,
    SessionsTable,
    IndividualSessionModal,
  },
  data: function () {
    return {
      loaded: false,
      scheduledRooms: [],
      scheduledRoomsToday: [],
      participant: {
        website_user_id: undefined,
      },
      roomsThisWeek: [],
      roomsNextWeek: [],
      shouldShowIndividualSessionModal: false,
      openedSession: {},
      upcomingRooms: [],
      shouldShowCancelBookingWarning: false,
    };
  },

  created: function () {
    this.loadScheduledRooms();
    this.loadCurrentWebsiteUser();
  },
  methods: {
    toggleIndividualSessionModal(room) {
      this.openedSession = room;
      this.shouldShowIndividualSessionModal =
        !this.shouldShowIndividualSessionModal;
    },
    loadScheduledRooms() {
      axios.get("/video_conference/rooms/scheduled.json").then((response) => {
        this.scheduledRooms = response.data.scheduled_rooms;
        this.scheduledRoomsTodayAll = response.data.scheduled_rooms_today_all;
        this.scheduledRoomsToday = this.scheduledRoomsTodayAll.filter(
          (room) =>
            room.future ||
            (room.scheduled_is_fifteen_mins_before_after_start_time &&
              room.current_user_booked)
        );
        this.roomsThisWeek = response.data.scheduled_rooms_this_week;
        this.roomsNextWeek = response.data.scheduled_rooms_next_week;
        this.loaded = true;
      });
    },

    loadCurrentWebsiteUser() {
      axios.get("/website_users/current_user.json").then((response) => {
        if (
          response.data &&
          response.data.current_website_user &&
          response.data.current_website_user.logged_in
        ) {
          this.participant.website_user_id =
            response.data.current_website_user.id;
        }
      });
    },
    prepareToCancelBooking() {
      this.shouldShowCancelBookingWarning =
        !this.shouldShowCancelBookingWarning;
    },
    joinRoom(room) {
      this.$router.push({ path: "/join/" + room.uuid });
    },
    cancelBooking(room) {
      axios
        .delete(
          "/video_conference/rooms/" +
            room.uuid +
            "/participants/" +
            this.participant.website_user_id +
            ".json"
        )
        .then((response) => {
          if (response.data.success) {
            room.current_user_booked = false;
            room.participant_count -= 1;
            this.loadScheduledRooms();
          } else {
            // Error?
          }
        });
    },
    futureAlert() {
      alert("Future work - to be added later");
    },
    handleEnterKey() {
      this.$router.push("/book/" + this.room.uuid);
    },
    handleSpaceKey() {
      this.$router.push("/book/" + this.room.uuid);
    },
  },

  computed: {
    noRoomsAreScheduled() {
      return (
        this.roomsThisWeek.length == 0 &&
        this.roomsNextWeek.length == 0 &&
        this.scheduledRoomsToday.length == 0 &&
        this.scheduledRooms.length == 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#content {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -90px;

  @media (max-width: 860px) {
    top: 0;
  }
}

.video-conferencing__rooms-index-container {
  flex-direction: column;
}

.video-conferencing-room__navigation-button {
  height: 130px;
}

.custom-container.video-conferencing__rooms-index {
  min-height: 700px;
}

.no-sesssions-text {
  display: flex;
  align-items: flex-start;
  color: rgb(72, 7, 120);
  margin-bottom: 40px;

  h2 {
    font-size: 20px;
    font-weight: bold;
  }
  svg {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 18px;
    }
  }
}

.video-conferencing__upcoming-wrapper {
  top: 0;
  left: 10px;
  margin-bottom: 40px;
}

.video-conferencing__rooms-index-container {
  top: 0;

  margin-bottom: 40px;
}
</style>
