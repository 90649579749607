<template>
  <div class="custom-container video-conferencing__homepage">
    <logoBar></logoBar>
    <!-- rooms starting in the next 15 mins or having started in last 15 mins -->
    <notification-banner :upcomingRooms="upcomingRooms"></notification-banner>
    <main
      id="content"
      class="video-conferencing__homepage-container"
      :class="participant.website_user_id ? 'logged-in' : ''"
    >
      <section class="video-conferencing__session-list">
        <template v-if="scheduledRoomsToday.length > 0">
          <h2 class="video-conferencing__session-list-heading">
            Today's Sessions:
          </h2>
          <div id="todaysSessions">
            <template v-for="(room, index) in scheduledRoomsToday">
              <template v-if="index <= 1">
                <article class="video-conferencing__session-card">
                  <div class="video-conferencing__session-card-inner">
                    <img
                      class="video-conferencing__session-card-clock"
                      alt=""
                      src="/images/video_conferencing/icon-clock.png"
                    />
                    <p class="video-conferencing__session-card-time">
                      {{ room.casual_start_time }}
                    </p>
                  </div>
                  <div class="video-conferencing__session-duration">
                    <img
                      alt=""
                      src="/images/video_conferencing/icon-session-length.png"
                    />
                    <div
                      :class="
                        room.duration.length > 2
                          ? 'video-conferencing__session-duration--long'
                          : 'video-conferencing__session-duration--short'
                      "
                    >
                      <span
                        :class="
                          room.duration.length > 4
                            ? 'duration-number--small'
                            : 'duration-number'
                        "
                        >{{ room.duration.slice(0, -1) }}</span
                      >
                      <span class="duration-letter">{{
                        room.duration.slice(room.duration.length - 1)
                      }}</span>
                    </div>
                  </div>
                  <h2 class="video-conferencing__session-card-title">
                    {{ room.name }}
                  </h2>
                  <p class="video-conferencing__session-card-text">
                    {{ room.description }}
                  </p>
                  <div class="video-conferencing__session-card-buttons">
                    <p
                      class="video-conferencing__session-card-non-bookable"
                      v-if="!room.bookable"
                    >
                      You must be logged in to book this session
                    </p>
                    <router-link
                      v-if="
                        !room.current_user_booked &&
                        (room.participant_limit === null ||
                          room.participant_count < room.participant_limit) &&
                        room.bookable
                      "
                      :to="'/book/' + room.uuid"
                      tag="button"
                      class="video-conferencing__session-book"
                      role="button"
                      @keydown.enter="handleEnterKey"
                      @keydown.space.prevent="handleSpaceKey"
                      tabindex="0"
                    >
                      Book Now
                    </router-link>

                    <button
                      class="video-conferencing__session-cancel"
                      v-if="
                        room.current_user_booked &&
                        !shouldShowCancelBookingWarning &&
                        !room.joinable
                      "
                      @click="prepareToCancelBooking()"
                    >
                      Cancel Booking
                    </button>

                    <button
                      class="video-conferencing__session-cancel"
                      v-if="
                        room.current_user_booked &&
                        shouldShowCancelBookingWarning
                      "
                      @click="cancelBooking(room)"
                    >
                      Are you sure?
                    </button>

                    <button
                      class="video-conferencing__session-join"
                      v-if="room.current_user_booked && room.joinable"
                      @click="joinRoom(room)"
                    >
                      Join Session
                    </button>

                    <button
                      @click="toggleIndividualSessionModal(room)"
                      v-if="
                        room.participant_limit === null ||
                        room.participant_count < room.participant_limit
                      "
                      class="video-conferencing__session-more"
                    >
                      More Info
                    </button>
                    <template
                      v-if="
                        !room.current_user_booked &&
                        room.participant_limit &&
                        room.participant_count === room.participant_limit
                      "
                    >
                      <p class="video-conferencing__session-full">Full!</p>
                    </template>
                  </div>
                </article>
                <hr class="video-conferencing__session-divider" />
              </template>
            </template>
          </div>
          <button
            class="video-conferencing__session-show-more"
            aria-label="Show more of today's sessions"
            aria-controls="todaysSessions"
            v-if="scheduledRoomsToday.length > 2"
            @click="showMoreSessions(scheduledRoomsToday, 'today')"
          >
            Show More
          </button>
        </template>
        <template v-if="scheduledRoomsTomorrow.length > 0">
          <h2 class="video-conferencing__session-list-heading">
            Tomorrow's Highlights:
          </h2>
          <div id="tomorrowsSessions">
            <template v-for="(room, index) in scheduledRoomsTomorrow">
              <template v-if="index <= 1">
                <article class="video-conferencing__session-card">
                  <div class="video-conferencing__session-card-inner">
                    <img
                      class="video-conferencing__session-card-clock"
                      alt=""
                      src="/images/video_conferencing/icon-clock.png"
                    />
                    <p class="video-conferencing__session-card-time">
                      {{ room.casual_start_time }}
                    </p>
                  </div>
                  <div class="video-conferencing__session-duration">
                    <img
                      alt=""
                      src="/images/video_conferencing/icon-session-length.png"
                    />
                    <div
                      :class="
                        room.duration.length > 2
                          ? 'video-conferencing__session-duration--long'
                          : 'video-conferencing__session-duration--short'
                      "
                    >
                      <span class="duration-number">{{
                        room.duration.slice(0, -1)
                      }}</span>
                      <span class="duration-letter">{{
                        room.duration.slice(room.duration.length - 1)
                      }}</span>
                    </div>
                  </div>
                  <h2 class="video-conferencing__session-card-title">
                    {{ room.name }}
                  </h2>
                  <p class="video-conferencing__session-card-text">
                    {{ room.description }}
                  </p>
                  <div class="video-conferencing__session-card-buttons">
                    <p
                      class="video-conferencing__session-card-non-bookable"
                      v-if="!room.bookable"
                    >
                      You must be logged in to book this session
                    </p>
                    <router-link
                      v-if="
                        !room.current_user_booked &&
                        (room.participant_limit === null ||
                          room.participant_count < room.participant_limit) &&
                        room.bookable
                      "
                      :to="'/book/' + room.uuid"
                      tag="button"
                      class="video-conferencing__session-book"
                    >
                      Book Now
                    </router-link>

                    <button
                      class="video-conferencing__session-cancel"
                      v-if="
                        room.current_user_booked &&
                        !shouldShowCancelBookingWarning
                      "
                      @click="prepareToCancelBooking()"
                    >
                      Cancel Booking
                    </button>

                    <button
                      class="video-conferencing__session-cancel"
                      v-if="
                        room.current_user_booked &&
                        shouldShowCancelBookingWarning
                      "
                      @click="cancelBooking(room)"
                    >
                      Are you sure?
                    </button>

                    <button
                      @click="toggleIndividualSessionModal(room)"
                      v-if="
                        room.participant_limit === null ||
                        room.participant_count < room.participant_limit ||
                        room.current_user_booked
                      "
                      class="video-conferencing__session-more"
                    >
                      More Info
                    </button>
                    <template
                      v-if="
                        !room.current_user_booked &&
                        room.participant_limit &&
                        room.participant_count === room.participant_limit
                      "
                    >
                      <p class="video-conferencing__session-full">Full!</p>
                    </template>
                  </div>
                </article>
                <hr class="video-conferencing__session-divider" />
              </template>
            </template>
          </div>
          <button
            class="video-conferencing__session-show-more"
            aria-label="Show more of tomorrow's sessions"
            aria-controls="tomorrowsSessions"
            v-if="scheduledRoomsTomorrow.length > 2"
            @click="showMoreSessions(scheduledRoomsTomorrow, 'tomorrow')"
          >
            Show More
          </button>
        </template>
        <navigationButtons
          :modifiedStylingForHomepage="true"
        ></navigationButtons>
      </section>

      <section class="video-conferencing__homepage-signposts">
        <div class="video-conferencing__signposts--col">
          <div class="video-conferencing__signposts--main">
            <router-link
              :to="'/cafe'"
              class="video-conferencing__signposts--link"
            >
              <img
                alt="Man smiling with mug"
                src="/images/hartlepoolatn/main-photo-man.jpg"
              />
              <div class="video-conferencing__signposts--main-text">
                <h1>Our Hub Cafe is Open!</h1>
                <p>Join the chat on weekdays <br />10am-4pm</p>
              </div>
              <div
                v-show="
                  currentWebsiteUser.logged_in && onlineBuddies.length > 0
                "
                class="
                  video-conferencing__signposts--main-btn-text
                  buddy-in-cafe-text-left
                "
              >
                <p>
                  <template v-if="onlineBuddies.length == 1">
                    One of your buddies is in the cafe
                  </template>

                  <template v-else>
                    {{ onlineBuddies.length }} of your buddies are in the cafe
                  </template>
                </p>
              </div>
              <p class="video-conferencing__signposts--main-btn-text">
                Enter Cafe
              </p>
            </router-link>
          </div>
          <div class="video-conferencing__signposts--row">
            <div class="video-conferencing__signposts--row-item">
              <router-link
                class="video-conferencing__signposts--link"
                to="/pages/about-the-hub"
              >
                <img alt="" src="/images/hartlepoolatn/btn-aboutv2.png" />
                <p>About the Hub</p>
              </router-link>
            </div>
            <div class="video-conferencing__signposts--row-item">
              <router-link
                class="video-conferencing__signposts--link"
                to="/pages/online-safety"
              >
                <img alt="" src="/images/hartlepoolatn/btn-safety.jpg" />
                <p>Online Safety</p>
              </router-link>
            </div>
            <div class="video-conferencing__signposts--row-item">
              <router-link :to="'/invite-friends'">
                <a class="video-conferencing__signposts--link" href="#">
                  <img alt="" src="/images/hartlepoolatn/btn-friend.jpg" />
                  <p>Invite a friend</p>
                </a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="video-conferencing__signposts--sidebar">
          <div
            class="
              video-conferencing__signposts--sidebar-item
              sidebar-item__sessions-block
            "
          >
            <router-link
              :to="'/sessions'"
              class="video-conferencing__signposts--link"
            >
              <img
                alt="Girl with a yoga mat"
                src="/images/hartlepoolatn/photo-yoga.png"
              />
              <div class="video-conferencing__signposts--sidebar-text">
                <h2
                  class="video-conferencing__signposts--sidebar-text--heading"
                >
                  Sessions
                </h2>
                <p class="video-conferencing__signposts--sidebar-text--inner">
                  Our upcoming <br />
                  activities
                </p>
              </div>
            </router-link>
          </div>
          <div class="video-conferencing__signposts--sidebar-item">
            <router-link
              :to="'/now_play'"
              class="video-conferencing__signposts--link"
            >
              <img
                alt="Woman on computer"
                src="/images/hartlepoolatn/photo-library.jpg"
              />
              <div class="video-conferencing__signposts--sidebar-text">
                <h2
                  class="video-conferencing__signposts--sidebar-text--heading"
                >
                  NowPlay
                </h2>
                <p class="video-conferencing__signposts--sidebar-text--inner">
                  Previous sessions <br />and videos
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </section>
    </main>

    <individual-session-modal
      v-if="shouldShowIndividualSessionModal == true"
      :room="openedSession"
      :participant="participant"
      @closeIndividualSessionModal="toggleIndividualSessionModal()"
    ></individual-session-modal>
  </div>
</template>

<script>
import axios from "axios";
import logoBar from "javascripts/video_conferencing/components/shared/logoBar";
import NotificationBanner from "./components/shared/notificationBanner.vue";
import navigationButtons from "javascripts/video_conferencing/components/shared/navigationButtons";
import IndividualSessionModal from "./components/sessions/IndividualSessionModal.vue";
import user from "./store/user";

export default {
  name: "videoConferencingHome",
  components: {
    logoBar,
    NotificationBanner,
    navigationButtons,
    IndividualSessionModal,
  },
  data: function () {
    return {
      scheduledRoomsToday: [],
      scheduledRoomsTomorrow: [],
      shouldShowCancelBookingWarning: false,
      participant: {
        website_user_id: undefined,
        buddies: [],
      },
      onlineBuddies: [],
      shouldShowIndividualSessionModal: false,
      logoutButton: { text: "Logout" },
      openedSession: {},
      upcomingRooms: [],
    };
  },
  created: function () {
    this.loadScheduledRooms();
    this.loadCurrentWebsiteUser();
    this.loadBuddies();

    this.sessionsPolling = setInterval(
      function () {
        this.loadScheduledRooms();
      }.bind(this),
      29000
    );

    this.buddiesPolling = setInterval(
      function () {
        this.loadBuddies();
      }.bind(this),
      61000
    );
  },
  methods: {
    // logout() {
    //   this.logoutButton.text = "Logging Out ...";
    //   user.dispatch("signOut");
    // },
    handleEnterKey() {
      this.$router.push("/book/" + this.room.uuid);
    },
    handleSpaceKey() {
      this.$router.push("/book/" + this.room.uuid);
    },

    toggleIndividualSessionModal(room) {
      this.openedSession = room;
      this.shouldShowIndividualSessionModal =
        !this.shouldShowIndividualSessionModal;
    },

    loadBuddies() {
      axios.get("/friendships.json").then((response) => {
        // console.log(response)

        this.participant.buddies = response.data.friends;

        this.participant.buddies.forEach((friend) => {
          if (
            friend.currently_in_lobby_or_room &&
            this.isAlreadyOnlineBuddy(friend) == false
          ) {
            this.onlineBuddies.push(friend);
          }
        });
      });
    },

    isAlreadyOnlineBuddy(friend) {
      if (this.onlineBuddies.length > 0) {
        this.onlineBuddies.forEach((buddy) => {
          if (buddy.id === friend.id) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        return false;
      }
    },
    loadScheduledRooms() {
      axios.get("/video_conference/rooms/scheduled.json").then((response) => {
        this.scheduledRoomsTomorrow = response.data.scheduled_rooms_tomorrow;
        this.scheduledRoomsTodayAll = response.data.scheduled_rooms_today_all;
        this.scheduledRoomsToday = this.scheduledRoomsTodayAll.filter(
          (room) =>
            room.future ||
            (room.scheduled_is_fifteen_mins_before_after_start_time &&
              room.current_user_booked)
        );
        this.upcomingRooms = this.scheduledRoomsTodayAll.filter(
          (room) =>
            room.joinable &&
            room.scheduled_is_fifteen_mins_before_after_start_time &&
            (room.current_user_booked || room.current_user_scheduled_admin)
        );
      });
    },
    loadCurrentWebsiteUser() {
      user.dispatch("loadUserFromSession");

      // Currently needed to populate "this.participant" - but could be rewritten?
      // note will likely need to happen in "then" action from previous user.dispatch
      // clause.
      axios.get("/website_users/current_user.json").then((response) => {
        if (
          response.data &&
          response.data.current_website_user &&
          response.data.current_website_user.logged_in
        ) {
          this.participant.website_user_id =
            response.data.current_website_user.id;
        }
      });
    },
    prepareToCancelBooking() {
      this.shouldShowCancelBookingWarning =
        !this.shouldShowCancelBookingWarning;
    },
    joinRoom(room) {
      this.$router.push({ path: "/join/" + room.uuid });
    },
    cancelBooking(room) {
      axios
        .delete(
          "/video_conference/rooms/" +
            room.uuid +
            "/participants/" +
            this.participant.website_user_id +
            ".json"
        )
        .then((response) => {
          if (response.data.success) {
            room.current_user_booked = false;
            room.participant_count -= 1;
          } else {
            // Error?
          }
        });
    },
    showMoreSessions(rooms, day) {
      rooms.push(rooms.splice(0, 2));
      rooms = rooms.flat();
      if (day === "today") {
        this.scheduledRoomsToday = rooms;
      } else if (day === "tomorrow") {
        this.scheduledRoomsTomorrow = rooms;
      }
    },
    futureAlert() {
      alert("Future work - to be added later");
    },
  },
  computed: {
    isLoggedIn() {
      return user.getters.isAuthenticated; // Is not reliable
    },
    currentWebsiteUser() {
      return user.state.user;
    },
  },
  beforeDestroy() {
    clearInterval(this.sessionsPolling);
    clearInterval(this.buddiesPolling);
  },
};
</script>

<style lang="scss" scoped>
// helps with accessibility when css is turned off
.video-conferencing__homepage-container {
  flex-direction: row-reverse;
}

// helps with accessibility when css is turned off
@media (max-width: 770px) {
  .video-conferencing__homepage-container {
    flex-direction: column-reverse;
  }
}
</style>
