<template>
    <section class="login-wrapper">

        <logoBar></logoBar>
        <navigation-buttons class="video-conferencing__navigation-buttons"></navigation-buttons>

        <main class="login" id="content">

            <h1>
                <font-awesome-icon icon="fa-solid fa-user" aria-hidden="true" role="presentation"/>
                Login to your account
            </h1>

            <!-- <p> Logged in users have access to all features </p> -->
            <form>
                <fieldset class="fieldset__email">
                    <label for="email"> Email: </label>
                    <input type="email" autocomplete="email" id="email" required aria-required="true" aria-autocomplete="email" name="email" placeholder="john@gmail.com" v-model="login_email" :aria-invalid="emailMissing">
                </fieldset>

               <fieldset class="fieldset__password">
                    <label for="password"> Password: </label>
                    <input type="password" autocomplete="password" id="password" required aria-required="true" aria-autocomplete="password" name="password" placeholder="Please enter your password" v-model="login_password" :aria-invalid="passwordMissing">
               </fieldset>

                <div class="buddies__message--error" v-if="login_error_message !== ''" role="alert" aria-live="polite">
                    <p> {{ login_error_message }} </p>
                </div>

                <fieldset class="fieldset__submit">
                    <button @click.prevent="submit" :aria-busy="[shouldShowLoadingMessage ? true : false ]" aria-live="polite" :aria-role="[shouldShowLoadingMessage ? 'alert' : 'button' ]">
                        <template v-if="shouldShowLoadingMessage">
                            {{ loadingText }}
                        </template>
                        <template v-else>
                            submit
                        </template>
                    </button>

                    <div class="fieldset__links">
                        <router-link to="/forgotten-password"> Forgotten your password? </router-link>
                        <router-link to="/register"> Create Account </router-link>
                    </div>
                </fieldset>
            </form>
        </main>
    </section>
</template>

<script>
import axios from "axios";
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import NavigationButtons from '../shared/navigationButtons.vue';
import user from "../../store/user";

export default {
    name: "Login",

    components: {
        logoBar,
        NavigationButtons,
    },
     data() {
        return {
            login_email: '',
            login_password: '',
            login_error_message: '',
            shouldShowLoadingMessage: undefined,
            loadingText: '',
            emailMissing: false,
            passwordMissing: false,
            fromRoom: ''
        }
    },
    created() {
        if(this.$route.params.uuid) {
            this.fromRoom = this.$route.params.uuid;
        }
    },

    methods: {
        submit() {
            this.emailMissing = false;
            this.passwordMissing = false;
            this.shouldShowLoadingMessage = true;
            this.loadingText = "Logging in..."
            // let authenticity_token = document.head.querySelector('meta[name="csrf-token"]').content;
            if(this.login_email !== '' && this.login_password !== '') {

                user.dispatch("signIn", {
                    email: this.login_email,
                    password: this.login_password,
                }).then((response) => {
                    if(this.fromRoom) {
                       this.$router.push({ path: '/join/' + this.fromRoom})
                    } else {
                        this.$router.push({ path: '/' });
                    }
                }).catch((response) => {
                    // can only have one element focused at the time
                    document.querySelector('#email').focus();
                    this.login_error_message = 'Invalid email or password. Please try again.';
                    this.login_password = '';
                    this.shouldShowLoadingMessage = false;
                    this.loadingText = ""
                });

            } else if(this.login_email == '') {

                this.emailMissing = true;
                this.shouldShowLoadingMessage = false;
                this.loadingText = ""
                document.querySelector('#email').focus();
                this.login_error_message = 'Please enter your email';

            } else if(this.login_password == '') {

                this.passwordMissing = true;
                this.shouldShowLoadingMessage = false;
                this.loadingText = ""
                document.querySelector('#password').focus();
                this.login_error_message = 'Please enter your password';

            }
        }
    }
}
</script>


<style lang="scss" scoped>
    .buddies__message--error {
        margin-bottom: 0;
        margin-top: 20px;
        max-width: 400px;
        width: 100%;
    }
</style>