<template>
    <div class="invite-friends__wrapper">
        <logoBar></logoBar>
        <section class="invitations">
            <navigationButtons></navigationButtons>
            <loading v-if="!is_loaded"></loading>

            <main id="content" v-if="isLoggedIn" class="invitations__columns">

                <div class="invitations__page-title">
                    <h1>
                        <font-awesome-icon icon="fas fa-users" aria-hidden="true" role="presentation"/>
                        Invite your friends
                    </h1>
                    <p> Community hub is better with friends! <br> Use the form below to invite yours</p>
                </div>
                
                <form class="invitations__form">
                    <fieldset>
                        <input type="text" name="name" id="name" v-model="inviteeName" required aria-required="true" autocomplete="name" placeholder="e.g John Smith" :aria-invalid="inviteeNameMissing">
                        <label for="name">Your Friend's Name <span class="required-star"> * </span></label>

                    </fieldset>

                    <fieldset>
                        <input type="email" name="email" id="email" v-model="inviteeEmail" required aria-required="true" autocomplete="email" placeholder="e.g john.smith@gmail.com" :aria-invalid="emailIsInvalid">
                         <label for="email">Their Email address <span class="required-star"> * </span></label>

                    </fieldset>

                    <fieldset>
                        <textarea rows="4" cols="50" id="invite-message" name="invite-message" v-model="personalisedInviteMessage" required aria-required="true" placeholder="e.g Hi John, please join me at Hartlepool All Together Now Community Hub" :aria-invalid="personalisedInviteMessageMissing"></textarea>
                        <label for="invite-message">Personalised invite message <span class="required-star"> * </span></label>
                    </fieldset>

                    <div class="buddies__message--success" v-show="successMessage !== '' && shouldShowSuccessMessage " role="alert" aria-live="polite">
                        <p> {{ successMessage }} </p>
                        <button class="close-error-message" @click="closeSuccessMessage" aria-label="hide message">
                            x
                        </button>
                    </div>

                    <div class="buddies__message--error" v-show="errorMessage !== '' && shouldShowErrorMessage" role="alert" aria-live="polite">
                        <p> {{ errorMessage }} </p>
                        <button class="close-error-message" @click="closeErrorMessage" aria-label="hide message">
                            x
                        </button>
                    </div>

                    <div class="buddies__message--error" v-if="shouldShowValidationMessage" role="alert" aria-live="polite">
                        <p> {{ validationMessage }} </p>
                        <button class="close-error-message" @click="closeValidationMessage" aria-label="hide message">
                            x
                        </button>
                    </div>

                    <button class="invite-button" @click.prevent="submitForm"> Send invitation </button>
                </form>
            </main>

            <main id="content" v-if="!isLoggedIn" class="login-notice">
                <h1> Invite your friends </h1>
                <p>This feature is only available to registered users</p>

                <div class="login-notice__ctas">
                    <router-link :to="'/register'">
                        Register
                    </router-link>

                    <router-link tag="button" :to="'/login'">
                        Login
                    </router-link>

                </div>
            </main>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import logoBar from 'javascripts/video_conferencing/components/shared/logoBar';
import navigationButtons from 'javascripts/video_conferencing/components/shared/navigationButtons';
import Loading from '../shared/loading.vue';

export default {
    name: 'InviteAFriend',
    components: {
        logoBar,
        navigationButtons,
        Loading,
    },
    data() {
        return {
            inviteeName: '',
            inviteeEmail: '',
            personalisedInviteMessage: '',
            isLoggedIn: false,
            participant: {
              website_user_id: undefined,
            },
            successMessage: '',
            errorMessage: '',
            shouldShowValidationMessage: undefined,
            validationMessage: '',
            fieldsAreValidated: undefined,
            is_loaded: false,
            shouldShowSuccessMessage: undefined,
            shouldShowErrorMessage: undefined,
            emailIsInvalid: false,
            inviteeNameMissing: false,
            personalisedInviteMessageMissing: false,
        }
    },
    created() {
        this.loadCurrentWebsiteUser();
    },
    methods: {
        closeValidationMessage() {
            this.shouldShowValidationMessage = false;
            this.validationMessage = '';
        },

        closeErrorMessage() {
            this.shouldShowErrorMessage = false;
            this.errorMessage = '';
        },

        closeSuccessMessage() {
            this.shouldShowSuccessMessage = false;
            this.successMessage = '';
        },

        loadCurrentWebsiteUser() {
            axios.get("/website_users/current_user.json").then((response) => {
                if (response.data && response.data.current_website_user && response.data.current_website_user.logged_in) {
                    this.isLoggedIn = true;
                    this.participant.website_user_id = response.data.current_website_user.id
                    this.is_loaded = true;
                }
                else {
                    this.is_loaded = true;
                }
            })
        },
        submitForm() {
            this.checkFieldsAreValidated();

            if(this.fieldsAreValidated == true) {
                this.errorMessage = '';
    
                axios.put('/website_users/current_users/' + this.participant.website_user_id + '/invite_a_friend.json', {
                    // authenticity_token: authenticity_token,
                    friends_name: this.inviteeName,
                    friends_email: this.inviteeEmail,
                    message: this.personalisedInviteMessage,
                }).then((response) => {
                    if(response.data.success === true) {
                        this.successMessage = 'Invite has been sent';
                        this.inviteeName = '';
                        this.inviteeEmail = '';
                        this.personalisedInviteMessage = '';
                        this.shouldShowErrorMessage = false;
                        this.shouldShowValidationMessage = false;
                        this.errorMessage = '';
                        this.validationMessage = ''

                        this.shouldShowSuccessMessage = true;
                    } else if(response.data.success === false && response.data.error === "invalid_email") {
                        this.errorMessage = 'Invalid email address, please correct'
                        this.is_sending_form_data = false;

                        this.shouldShowErrorMessage = true
                    } else {
                        this.errorMessage = 'Unable to send an invite';

                         this.shouldShowErrorMessage = true
                    }
                })
            }
        },

        checkFieldsAreValidated() {
 
            this.emailIsInvalid = false;
            this.inviteeNameMissing = false;
            this.personalisedInviteMessageMissing = false;

            this.validationMessage = "";
            
            let regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
            
            if(this.inviteeEmail !== '' && this.inviteeEmail.match(regex) && this.inviteeName !== ''  && this.personalisedInviteMessage !== '') {
                this.fieldsAreValidated = true
            } 

            else if (
                this.inviteeName == ''
            ) {
                document.querySelector('#name').focus();
                this.validationMessage = "Please enter your friend's name";
                this.fieldsAreValidated = false;
                this.shouldShowValidationMessage = true;
                this.inviteeNameMissing = true;
            } 

            else if ( 
                this.inviteeEmail == ''  ||  !this.inviteeEmail.match(regex)
            ) {
                this.emailIsInvalid = true;
                document.querySelector('#email').focus();
                this.validationMessage = "Please enter a valid email address";
                this.fieldsAreValidated = false;
                this.shouldShowValidationMessage = true;
            }

            else if (this.personalisedInviteMessage == '') {
                document.querySelector('#invite-message').focus();
                this.validationMessage = "Please enter a personalised invite message";
                this.fieldsAreValidated = false;
                this.shouldShowValidationMessage = true;
                this.personalisedInviteMessageMissing = true;
            }
        }
    }
}
</script>

<style scoped lang="scss">


    .invitations {
        @media screen and (min-width: 768px) {
            position: relative;
            top: -80px;
        }
    }

    .invitations__columns {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .invitations__page-title {
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-self: center;

        h1 {
            color: rgb(72, 7, 120);
            text-align: center;

            @media (max-width: 500px) {
                font-size: 32px;
            }
        }

        p {
            text-align: center;
        }
    }
    .invitations__form {
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-self: center;
        width: 50%;
        margin: 20px;

        @media (max-width: 500px) {
            width: 80%;
        }

        label {
            font-weight: bold;
        }

        input, textarea {
            border-radius: 4px;
            border: 1px solid grey;
            text-indent: 10px;
            margin-bottom: 20px;


            &:focus {
                + label {
                    outline: none;
                }
            }

            &:focus, &:target {
                outline: 3px dashed #D2014A;
            }
        }


        // allows custom :focus styling
        fieldset {
            display: flex;
            flex-direction: column-reverse;
        }

        /// overrides defaults
        textarea:focus, textarea:target {
            outline:none;
            border: 3px dashed #D2014A;
            + label {
                outline: none;
            }
        }

        label sup {
            color: #D2014A;
        }
        button.invite-button {
            padding: 12px 35px;
            text-transform: lowercase;
            background-color: #74ba51;
            color: #333333;
            font-size: 22px;
            font-weight: bold;
            border-radius: 10px;
            border: none;
            margin: 10px;

            &:hover {
                background-color: darken(#74ba51, 10%);
            }
        }
    }

    .login-notice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 500px;

        h1 {
            color: #0057AE;
            text-align: center;
        }
        p {
            text-align: center;
        }

        .login-notice__ctas {
            display: flex;

            @media (max-width: 450px) {
                flex-direction: column;
            }
            
            a {
                padding: 12px 35px;
                text-transform: lowercase;
                background-color: #74ba51;
                color: #333333;
                font-size: 22px;
                font-weight: bold;
                border-radius: 10px;
                border: none;
                margin: 10px;

                &:hover {
                    background-color: darken(#74ba51, 10%);
                } 
            }

            button {
                padding: 12px 35px;
                text-transform: lowercase;
                background-color: #0057AE;
                color: white;
                font-size: 22px;
                font-weight: bold;
                border-radius: 10px;
                border: none;
                margin: 10px;

                &:hover {
                    background-color: darken(#0057AE, 10%);
                } 
            }
        }

    }


</style>